import CryptoJS from "crypto-js";
export default {
    // 加密函数
    encrypt(str, KEY, IV) {
        var key = CryptoJS.enc.Utf8.parse(KEY);
        var iv = CryptoJS.enc.Utf8.parse(IV);
        var encrypted = CryptoJS.AES.encrypt(str, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    },
   
    //解密
    decrypt(str, KEY, IV) {
            var key = CryptoJS.enc.Utf8.parse(KEY);
            var iv = CryptoJS.enc.Utf8.parse(IV);
            var decrypt = CryptoJS.AES.decrypt(str, key, { 
            iv:iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypt.toString(CryptoJS.enc.Utf8);
    }
};
