import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/global.css';

import InfiniteScroll from "@/components/InfiniteScroll.vue";
Vue.component('InfiniteScroll', InfiniteScroll);

Vue.prototype.$myAlert = function (options) {
  return MyMessageBox.alert(options);
};

Vue.use(ElementUI)
Vue.prototype.$store = store;

Vue.config.productionTip = false
import AES from './libs/AES';
Vue.prototype.$aes = AES;

(function (doc, win) {
  let docEL = doc.documentElement;
  let resizeEvent = "orientationchange" in window ? "orientationchange" : "resize",
    recalc = function () {
      const clientWidth = docEL.clientWidth;
      if (!clientWidth) return;
      if (clientWidth > 750) {
        docEL.style.fontSize = "100px";
      } else {
        docEL.style.fontSize = (clientWidth / 750) * 100 + "px";
      }
    }

  recalc();
  win.addEventListener(resizeEvent, recalc, false);
  doc.addEventListener("DOMContentLoaded", recalc, false)

})(document, window)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
