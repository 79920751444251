<template>
  <div ref="container"></div>
</template>

<script>
export default {
  name: "InfiniteScroll",
  props: {
    threshold: {
      default: 0,
      type: Number,
    },
    hasMore: {
      default: false,
      type: Boolean,
    },
    loadMoreFn: {
      default: () => {},
      type: Function,
    },
    topActionFn: {
      default: () => {},
      type: Function,
    },
    el: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      atTop: false,
    };
  },
  mounted() {
    this.setupScrollListener();
  },
  beforeDestroy() {
    this.removeScrollListener();
  },
  methods: {
    setupScrollListener() {
      if (this.el) {
        this.$nextTick(() => {
          const el = document.querySelector(this.el);
          if (el) {
            el.addEventListener("scroll", this.scroll);
          }
        });
      } else {
        window.addEventListener("scroll", this.scroll);
      }
    },
    removeScrollListener() {
      if (this.el) {
        const el = document.querySelector(this.el);
        if (el) {
          el.removeEventListener("scroll", this.scroll);
        }
      } else {
        window.removeEventListener("scroll", this.scroll);
      }
    },
    async scroll(e) {
      if (this.loading) return;

      let el = this.el ? document.querySelector(this.el) : document.documentElement;
      const threshold = this.threshold || 10;
      let scrollTop = this.el ? el.scrollTop : window.pageYOffset;
      const docHeight = el.scrollHeight - (this.el ? el.offsetHeight : window.innerHeight);

      // 检查是否在顶部
      if (scrollTop <= threshold) {
        this.atTop = true;
      } else {
        this.atTop = false;
      }

      // 如果在顶部并且用户向上滚动
      if (this.atTop && scrollTop==0) {
        this.topActionFn();
      }
      // 检查是否在底部并且需要加载更多
      // console.log(docHeight,scrollTop ,threshold,this.hasMore);
      if ((docHeight <= (scrollTop + threshold)) && this.hasMore) {
        this.loading = true;
        try {
          await this.loadMoreFn();
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>