import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue'),
    children: [
      {
        path: 'dial/dial',
        name: 'dial',
        component: () => import('@/views/dial/dial.vue')
      },
      {
        path: 'task/task',
        name: 'task',
        component: () => import('@/views/task/task.vue')
      },
      {
        path: 'custom/custom',
        name: 'custom',
        component: () => import('@/views/custom/custom.vue')
      },
      {
        path: 'user/user',
        name: 'dial',
        component: () => import('@/views/user/user.vue')
      },
      {
        path: 'setup/call-binding',
        name: 'binding',
        component: () => import('@/views/setup/call-binding.vue')
      },
      {
        path: 'setup/basicSetting',
        name: 'basicSetting',
        component: () => import('@/views/setup/basicSetting.vue')
      },
      {
        path: 'setup/security',
        name: 'security',
        component: () => import('@/views/setup/security.vue')
      },
      {
        path: 'calldetails/detail',
        name: 'details',
        component: () => import('@/views/calldetails/details.vue'),
      },
      {
        path: 'task/taskInfo',
        name: 'taskInfo',
        component: () => import('@/views/task/taskInfo.vue')
      },
      {
        path: 'custom/addCustom',
        name: 'addCustom',
        component: () => import('@/views/custom/addCustom.vue')
      },
      {
        path: 'custom/customInfo',
        name: 'customInfo',
        component: () => import('@/views/custom/customInfo.vue')
      },
      {
        path: 'user/myUser',
        name: 'myUser',
        component: () => import('@/views/user/myUser.vue')
      },
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
