<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
export default {

}

</script>

<style lang="scss">
.input-box {
	padding: 10px;
	font-size: 15px;

	.input-item {
		display: flex;
		background: white;
		border-bottom: 1px solid #eeeeee;
		line-height: 50px;
		.input-label {
			width: 80px;
			display: flex;
			align-items: center;

			image {
				margin-right: 10px;
			}
		}

		.input-body {
			position: relative;
			height: 50px;
			width: calc(100% - 75px);

			.input {
				line-height: 50px;
				height: 50px;
				position: relative;
				font-size: 14px;
			}

			.eye {
				position: absolute;
				height: 25px;
				width: 25px;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}

			.btn-code {
				position: absolute;
				z-index: 10;
				right: 0px;
				top: 50%;
				transform: translateY(-50%);
				background: none;
				color: #205592;
				width: 80px;
				font-size: 12px;
				box-sizing: border-box;
				text-align: right;
				padding: 0;
				height: 50px;
				line-height: 50px;
			}
		}

	}
}
</style>
