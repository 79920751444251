import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
	callFlag: 0,
	loginFlag: 0,
	userInfo: {},
	token: {},
	callNum: 3,
	sipPhone: 133, //sip来电号码
	popupWindowFlag: false,		//弹窗显示来电标记
	lineStatusFlag: 2,		//sip电话置闲置忙状态标记  1 置闲  2 置忙
	automaticResponseFlag: false,	//sip电话自动应答
	title: false,
	customId: 5,
	socketShow: false,
	isValid: true,
	onCallHook:false,
	sipCallPhone:null
}
const mutations = {
	setOnCallHook(state, onCallHook) {
		state.onCallHook = onCallHook;
	},
	setSipCallPhone(state, sipCallPhone) {
		state.sipCallPhone = sipCallPhone;
	},
	setIsValid(state, isValid) {
		state.isValid = isValid;
	},
	setSocketShow(state, socketShow) {
		state.socketShow = socketShow
	},
	// 数据统计跳转
	setCustom(state, customId) {
		state.customId = customId
	},
	setTitle(state, title) {
		state.title = title;
	},
	setlineStatusFlag(state, lineStatusFlag) {
		state.lineStatusFlag = lineStatusFlag;
	},
	setautomaticResponseFlag(state, automaticResponseFlag) {
		state.automaticResponseFlag = automaticResponseFlag;
	},
	setpopupWindowFlag(state, popupWindowFlag) {
		state.popupWindowFlag = popupWindowFlag;
	},
	setsipPhone(state, sipPhone) {
		state.sipPhone = sipPhone;
	},
	changeCallFlag(state, callFlag) {
		state.callFlag = callFlag;
	},
	changeLoginFlag(state, loginFlag) {
		state.loginFlag = loginFlag;
	}
}
const actions = {

}
const getters = {

}
const store = new Vuex.Store({
	state,
	getters,
	mutations
})
export default store
